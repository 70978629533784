import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from '../../shared/components/alert/alert.component';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(private ngbModal: NgbModal) {}

  show(
    title: string,
    message: string,
    yes: string = 'OK',
    no: string = '',
    alertClass: string = ''
  ): NgbModalRef {
    let modalRef: NgbModalRef = this.ngbModal.open(AlertComponent, {
      centered: true,
      backdrop: 'static',
      keyboard: false,
      windowClass: 'alert-modal',
    });
    modalRef.componentInstance.alertClass = alertClass;
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = message;
    modalRef.componentInstance.yes = yes;
    modalRef.componentInstance.no = no;
    return modalRef;
  }
}
