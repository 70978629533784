<div class="alert-box {{ alertClass }}">
    <div class="modal-header">
      <button type="button" class="close-btn m-l" (click)="modal.close(false)">
        <span>&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <img [src]="alertImageByAlertTitle()" />
      <div>
        <h5>{{ alertHeadingByAlertTitle() }}</h5>
      </div>
      <div class="alert-text">
        <span [innerHTML]="message"></span>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-secondary"
        (click)="modal.close(false)"
        [hidden]="!no"
      >
        {{ no }}
      </button>
      <button
        type="button"
        class="btn"
        [ngClass]="title === modalPopupEnum.DELETE ? 'btn-red' : 'btn-primary'"
        (click)="modal.close(true)"
      >
        {{ yes }}
      </button>
    </div>
  </div>
  