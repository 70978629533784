import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { setQuestionFilter, setQuestionFilterSuccess  } from './user.actions';

// store feature key
export const selectQuestionsFeatureKey = 'questions';

// get store
export const selectQuestionState =
  createFeatureSelector<QuestionState>(selectQuestionsFeatureKey);

// initial state
export const initialState: QuestionState = {
  quesFilter: {} as any,
};

// main store
export interface QuestionState {
  quesFilter: any;
}

// reducer
export const getQuestionsReducer = createReducer(
  initialState,
  on(setQuestionFilter, (state, {quesFilter: quesFilter}) => {
    return { 
      ...state, 
      quesFilter
    };
  }),
  
  on(setQuestionFilterSuccess, (state) => {
    return { 
      ...state, 
      quesFilter : state
    };
  }),
);

// selecters
export const getQuestionFilter = createSelector(
  selectQuestionState,
  (state: QuestionState) => state.quesFilter
);