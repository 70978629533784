<div class="error">
    <h6>Sorry, Page not found</h6>
    <p>Looks like page you are trying to visit does not exist.</p>
    <img src="/assets/images/ERROR.svg" alt="Error" />
    <div class="action">
      <button type="button" class="btn btn-outline-primary">
        <a class="btn-links" [routerLink]="'/auth/login'">Go back</a>
      </button>
    </div>
  </div>
  