import { HttpErrorResponse, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { UtilityService } from './utility.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const utility = inject(UtilityService);

  return next(req).pipe(
    tap((evt: any) => {
      if (evt instanceof HttpResponse) {
        
      }
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.status === 400) {
        utility.setErrorMessages(error?.error);
      }
      throw error;
    })
  );
};
