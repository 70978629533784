import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPopupEnum } from '../../../core/const/constant';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  title!: string;
  message!: string;
  error!: string;
  yes!: string;
  no!: string;
  alertClass!: string;
  modalPopupEnum = ModalPopupEnum;

  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}

  alertImageByAlertTitle() {
    if (this.title === ModalPopupEnum.WARNING) {
      return '/assets/images/popup/warning.svg';
    } else if (this.title === ModalPopupEnum.CONFIRMATION) {
      return '/assets/images/popup/info.svg';
    } else if (this.title === ModalPopupEnum.Error) {
      return '/assets/images/popup/error.svg';
    } else if (this.title === ModalPopupEnum.ALERT) {
      return '/assets/images/popup/info.svg';
    } else if (this.title === ModalPopupEnum.DELETE) {
      return '/assets/images/popup/bin.svg';
    } else if (this.title === ModalPopupEnum.SUCCESS) {
      return '/assets/images/popup/check_circle.svg';
    } else if (this.title === ModalPopupEnum.WAIT_WARNING) {
      return '/assets/images/popup/watch_later.svg';
    } else if (this.title === ModalPopupEnum.DAILY_CHALLENGE) {
      return '/assets/images/play/goal.svg';
    } else {
      return '/assets/images/popup/info.svg';
    }
  }

  alertHeadingByAlertTitle() {
    if (this.title === ModalPopupEnum.WARNING) {
      return 'WARNING';
    } else if (this.title === ModalPopupEnum.CONFIRMATION) {
      return 'CONFIRMATION';
    } else if (this.title === ModalPopupEnum.Error) {
      return 'Error';
    } else if (this.title === ModalPopupEnum.ALERT) {
      return 'ALERT';
    } else if (this.title === ModalPopupEnum.DELETE) {
      return 'DELETE';
    } else if (this.title === ModalPopupEnum.SUCCESS) {
      return 'SUCCESS';
    } else if (this.title === ModalPopupEnum.WAIT_WARNING) {
      return 'WAIT_WARNING';
    } else {
      return this.title;
    }
  }
}
