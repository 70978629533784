import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable
({
  providedIn: 'root',
})
export class LoaderService {
  subject = new BehaviorSubject(false);
  constructor() { }

  show(): void {
    this.subject.next(true);
  }
  hide(): void {
    this.subject.next(false);
  }

  getState(): Observable<boolean> {
    return this.subject.asObservable();
  }
}
 