import { createAction, props } from "@ngrx/store";

export enum ActionTypes{
  setQuestionFilter='[Questions] Set Question Filter',
}

export const setQuestionFilter = createAction(
  ActionTypes.setQuestionFilter,
  props<{quesFilter: Object}>()
);

export const setQuestionFilterSuccess = createAction(
  ActionTypes.setQuestionFilter,
  props<{ payload: any }>()
);

export const setQuestionFilterFailure = createAction(
  ActionTypes.setQuestionFilter,  props<{ error: any }>()
);

//  reset
export const resetsetQuestionFilter = createAction(
  ActionTypes.setQuestionFilter
);

