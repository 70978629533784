import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from './auth.service';
import { LoaderService } from './loader.service';
import { finalize } from 'rxjs';

let pendingRequests = 0; // Counter to track pending requests

export const apiInterceptor: HttpInterceptorFn = (req, next) => {
  const loader = inject(LoaderService);
  
  if (pendingRequests === 0) {
    loader.show(); // Show loader if there are no pending requests
  }
  
  pendingRequests++; // Increment pendingRequests for each new request

  const authToken = inject(AuthService).getAccessToken();
  const authReq = req.clone({
    setHeaders: { 'Authorization': `Bearer ${authToken}` }
  });

  return next(authReq).pipe(
    finalize(() => {
      pendingRequests--; // Decrement pendingRequests when request completes
      if (pendingRequests === 0) {
        loader.hide(); // Hide loader if there are no more pending requests
      }
    })
  );
};