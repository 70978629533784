import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterOutlet } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { AuthService } from './core/services/auth.service';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LoaderService } from './core/services/loader.service';
import { MatIconModule } from '@angular/material/icon';
import { faArrowDownAZ } from '@fortawesome/free-solid-svg-icons';
import { ApiService } from './core/services/api.service';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { environment } from '@env/environment.prod';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [MatMenuModule, CommonModule, HttpClientModule, RouterOutlet, RouterLink,
    MatIconModule,CollapseModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'prepstudy-ui-dashboard';
  loading: boolean = false;
  faArrowDownAZ = faArrowDownAZ;
  loggedIn: boolean = false;
  user: any = {};
  isSidebarCollapsed = false;
  isCollapsed = true;
  isCollapsed1 = true;
  isCollapsed2 = true;
  isCollapsed3 = true;
  isCollapsed4 = true;
  isCollapsed5 = true;
  isCollapsed6 = true;
  isCollapsed7 = true;
  isCollapsed8 = true;
  public version: any = environment.appVersion;

  toggleSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
  }
  constructor(private auth: AuthService, public router: Router,
    private cd: ChangeDetectorRef,
    private loaderService: LoaderService, private apiService: ApiService,) { }

  ngOnInit(): void {
    this.loaderService.getState().subscribe((status) => {
      this.loading = status;
      this.cd.detectChanges();
    });

    this.user = this.auth.getUser();
    this.loggedIn = this.auth.loggedIn();

    this.auth.loginHook().subscribe((user) => {
      this.user = user;
      this.loggedIn = true;
      this.cd.detectChanges();
    });

    this.auth.logoutHook().subscribe(() => {
      this.user = {};
      this.loggedIn = false;
      this.cd.detectChanges();
    });
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/auth/login']);
  }

  activeLink: HTMLElement | null = null;

  setActiveLink(event: Event): void {
    if (this.activeLink) {
      this.activeLink.classList.remove('active');
    }
    this.activeLink = event.target as HTMLElement;
    this.activeLink.classList.add('active');
  }

}
