import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CommonEnum } from '../const/constant';
import { Users } from '../models/users';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private API: string = environment.BASE_PATH;
  private jwtHelper: JwtHelperService = new JwtHelperService();
  private loginEvent: Subject<boolean> = new Subject<boolean>();
  private logoutEvent: Subject<boolean> = new Subject<boolean>();
  constructor(private http: HttpClient, private router: Router) { }


  createSession(resp: any) {
    let data = resp.data.user as Users;
    if (
      resp.status == 'SUCCESS' &&
      resp?.data?.user?.status != null &&
      resp?.data?.user?.status.toLowerCase() === CommonEnum.Version.toLowerCase()
    ) {
      localStorage.setItem('access', resp.data.access);
      localStorage.setItem('refresh', resp.data.refresh);
      localStorage.setItem('user', JSON.stringify(data));
      this.loginEvent.next(true);
    }
  }

  setAccessToken(resp: any) {
    if (resp.status == 'SUCCESS') {
      localStorage.setItem('access', resp.data.access);
      this.loginEvent.next(true);
    }
  }

  clearSession() {
    localStorage.removeItem('user');
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    this.logoutEvent.next(true);
  }

  logout(): void {
    this.clearSession();
  }

  loginHook(): Observable<boolean> {
    return this.loginEvent.asObservable();
  }

  getAccessToken() {
    return localStorage.getItem('access');
  }

  isAccessTokenExpired() {
    return (
      !this.getAccessToken() ||
      (this.getAccessToken() &&
        this.jwtHelper.isTokenExpired(this.getAccessToken()))
    );
  }

  getRefreshToken() {
    return localStorage.getItem('refresh');
  }

  refreshToken() {
    return this.http.post<any>(`${this.API}/auth/refresh-token`, {
      refresh: this.getRefreshToken(),
    });
  }

  isRefreshTokenExpired() {
    return (
      !this.getRefreshToken() ||
      (this.getRefreshToken() &&
        this.jwtHelper.isTokenExpired(this.getRefreshToken()))
    );
  }

  logoutHook(): Observable<boolean> {
    return this.logoutEvent.asObservable();
  }

  getUser(): any {
    const data = localStorage.getItem('user');
    return data ? JSON.parse(data) : {};
  }


  loggedIn(): boolean {
    if (!this.getRefreshToken()) {
      return false;
    } else if (this.jwtHelper.isTokenExpired(this.getRefreshToken())) {
      this.logout();
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }

  login(form: any): Observable<any> {
    return this.http
      .post<any>(`${this.API}/auth/login`, form)
      .pipe(tap((data) => this.createSession(data)));
  }

}
